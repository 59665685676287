import React, { useState } from "react"
import { useAuth } from "../contexts/AuthContext"
import { useHistory } from "react-router-dom"
import Navbar from "./routes/Navbar";
import Demographics from "./dashboard/Demographics";
import Tests from "./dashboard/Tests";
import Footer from "./routes/footer";
import "./css/style.css";
import { COLORS} from "./color";

export default function Dashboard() {
  const [setError] = useState("")
  const { logout } = useAuth()
  const history = useHistory()
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(true);
  const [third, setThird] = useState(true);
  const [fourth, setFourth] = useState(true);
  const [FirstThird, setFirstThird] = useState(false);
  const [SecondFourth, setSecondFourth] = useState(false);
  const [all, setAll] = useState(true);
  const [disabledFirst, setdisabledFirst] = useState(true);
  const [disabledSecond, setdisabledSecond] = useState(true);
  const [disabledThird, setdisabledThird] = useState(true);
  const [disabledFourth, setdisabledFourth] = useState(true);
  const [highlightCard, sethighlightCard] = useState(-1);
  const [location, setlocation] = useState('All');
  const [dept, setdept] = useState('All');

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  function clickIcon(e) {
    if (e.target.id == "button1" && e.target.checked == true) {
      setdisabledFirst(true);
      setdisabledSecond(true);
      setdisabledThird(true);
      setdisabledFourth(true);
      setFirst(true);
      setSecond(false);
      setThird(true);
      setFourth(false);
      setFirstThird(true);
      setSecondFourth(false);
      setAll(false);
    }
    else if (e.target.id == "button1" && e.target.checked == false) {
      setdisabledFirst(false);
      setdisabledSecond(false);
      setdisabledThird(false);
      setdisabledFourth(false);
      setFirst(false);
      setThird(false);
      setFirstThird(false);
    }
    else if (e.target.id == "button2" && e.target.checked == true) {
      setdisabledFirst(true);
      setdisabledSecond(true);
      setdisabledThird(true);
      setdisabledFourth(true);
      setFirst(false)
      setSecond(true);
      setThird(false);
      setFourth(true);
      setSecondFourth(true);
      setFirstThird(false);
      setAll(false);
    }
    else if (e.target.id == "button2" && e.target.checked == false) {
      setdisabledFirst(false);
      setdisabledSecond(false);
      setdisabledThird(false);
      setdisabledFourth(false);
      setSecond(false);
      setFourth(false);
      setSecondFourth(false);
    }
    else if (e.target.id == "button3" && e.target.checked == true) {
      setdisabledFirst(true);
      setdisabledSecond(true);
      setdisabledThird(true);
      setdisabledFourth(true);
      setSecond(true);
      setFourth(true);
      setFirst(true);
      setThird(true);
      setFirstThird(false);
      setSecondFourth(false);
      setAll(true);
    }
    else if (e.target.id == "button3" && e.target.checked == false) {
      setSecond(false);
      setFourth(false);
      setFirst(false);
      setThird(false);
      setAll(false);
      setFirstThird(false);
      setSecondFourth(false);
      setdisabledFirst(false);
      setdisabledSecond(false);
      setdisabledThird(false);
      setdisabledFourth(false);
      
    }
    else if (e.target.id == "button4") {
      setdisabledFirst(false);
      setdisabledSecond(false);
      setdisabledThird(false);
      setdisabledFourth(false);
      setSecond(false);
      setFourth(false);
      setFirst(false);
      setThird(false);
      setAll(false);
      setFirstThird(false);
      setSecondFourth(false);
    }
    else if (e.target.id == "checkbox1" && e.target.checked == true) {
      setFirst(true);
      setdisabledSecond(true);
      setdisabledThird(true);
      setdisabledFourth(true);
    }
    else if (e.target.id == "checkbox2" && e.target.checked == true) {
      setSecond(true);
      setdisabledFirst(true);
      setdisabledThird(true);
      setdisabledFourth(true);
    }
    else if (e.target.id == "checkbox3" && e.target.checked == true) {
      setThird(true);
      setdisabledSecond(true);
      setdisabledFirst(true);
      setdisabledFourth(true);
    }
    else if (e.target.id == "checkbox4" && e.target.checked == true) {
      setFourth(true);
      setdisabledSecond(true);
      setdisabledThird(true);
      setdisabledFirst(true);
    }
    else if (e.target.id == "checkbox1" && e.target.checked == false) {
      setFirst(false);
      setdisabledSecond(false);
      setdisabledThird(false);
      setdisabledFourth(false);
    }
    else if (e.target.id == "checkbox2" && e.target.checked == false) {
      setSecond(false);
      setdisabledFirst(false);
      setdisabledThird(false);
      setdisabledFourth(false);
    }
    else if (e.target.id == "checkbox3" && e.target.checked == false) {
      setThird(false);
      setdisabledSecond(false);
      setdisabledFirst(false);
      setdisabledFourth(false);
    }
    else if (e.target.id == "checkbox4" && e.target.checked == false) {
      setFourth(false);
      setdisabledSecond(false);
      setdisabledThird(false);
      setdisabledFirst(false);
    }

    console.log(first);
  }



  function clickCard(idx)
  {
    sethighlightCard(idx)
  }

  function handleDropDown(e)
  {
    if(e.target.id === 'loc')
    {
      setlocation(e.target.value);
    }
    else if(e.target.id === 'dept')
    {
      setdept(e.target.value);
    }

  }

  
  return (
    <>
     <div className="set">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 ">
          <Navbar page="dashboard"/>
        </div>
      </div>
      <div className="row div-covers" style={{backgroundColor: COLORS.background_color}}>
        <div className="col-lg-7 col-md-7 col-sm-11 text-left my-4">
          <Tests highlightCard={highlightCard} old_male={first} old_female={second} young_male={third} young_female={fourth} location={location} dept={dept}/>
        </div>
        <div className="col-lg-5 col-md-4 col-sm-12 text-left">
          <Demographics clickCard={clickCard} clickIcon={clickIcon} location={location} dept={dept} FirstThird={FirstThird} SecondFourth={SecondFourth} all={all} first={first} second={second} third={third} fourth={fourth} disabledFirst={disabledFirst} disabledSecond={disabledSecond} disabledThird={disabledThird} disabledFourth={disabledFourth} handleDropDown={handleDropDown}/>
        </div>
      </div>
      <div className="row margin-below">
          <Footer />
      </div>
      </div>  
    </>
  )
}
