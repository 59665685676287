// progressbar color - according to index
const green_max = [10, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 10, 10, 20, 20, 20];
const orange_max = [15, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 10, 10, 20, 20, 20];

// Age >= below const considered as old
const old_min_limit = 35;

// Index of profile in json
// var convert_test_index = {
//   "Complete Blood Count": 0, // 0 - index
//   "Kidney": 1, // 1 - index
//   "Lipids": 2, // 2 - index
//   "Thyroid": 3, // 3 - index
//   "Liver": 4, // 4 - index
//   "Diabetes": 5, // 5 - index
//   "Vitamin D": 6, // 6 - index
//   "Vitamin B12": 7, // 7 - index
//   "Electrolytes & Minerals": 8, // 8 - index
//   "Anemia": 9, // 9 - index
//   "Urine": 10, // 10 - index
//   "Body Screening": 11, // 10 - index
//   "BP & BMI": 12, // 10 - index
//   "Covid": 13,
//   "Body System": 14,
//   "Lifestyle": 15,
//   // add new biomarker with index here
// };

// Index of profile in json
// according to the inputs 29-12-2021 (case7-new.xlsx)
var convert_test_index = {
  "Complete Blood Count": 0, // 0 - index
  "Diabetes Monitoring": 1,
  "Kidney Profile": 2,
  "Lipid Profile": 3,
  "Liver Profile": 4,
  "BMI & BP": 5,
  "Electrolyte Profile": 6,
  "Urinalysis": 7,
  // add new biomarker with index here
};

// Index to Test name
var convert_index_test = {};
var total_bio_markers = 0;
for (var it in convert_test_index) {
  total_bio_markers += 1;
  console.log("the value", it)
  convert_index_test[convert_test_index[it]] = it; // value = key (from convert_test_index)
}
console.log("convert_index_test\n", convert_index_test)

// JSON data link
// var JSON_LINK =
//   "https://gist.githubusercontent.com/Kartiktyagi3/7111cd065c3043c47a37d1cb00a5671b/raw/e9952a59546e16932e9d5e1028d891b56fb6c188/orangeStar_1.json";

// NEW JSON LINK (Jan 2022)
//var JSON_LINK = "https://gist.githubusercontent.com/YashMarmat/d0ebf5564eb2d60f1aa68934017e0d85/raw/4be28e439533753391bb9da10f43eae5f270fcd4/december-json.json"
var JSON_LINK = "https://gist.githubusercontent.com/YashMarmat/d0ebf5564eb2d60f1aa68934017e0d85/raw/f1cc093ec4fad85e3deb50fe0f2db8f8e6dfe22b/december-json.json"



// Location and Department arr

//var loc_arr = ["Mixed", "Vegetarian"];

var loc_arr = ['ALL'];

// var dept_arr = [
//   'Cogen',
//   'Store',
//   'Safety',
//   'LGMD',
//   'QC',
//   'Maintainence',
//   'DMH',
//   'ETP',
//   'Electrical',
//   'Instrumentation',
//   'Starch', 'Purchase',
//   'Industrial Purchase',
//   'Stores', 'Logistics',
//   'CWM',
//   'Manufacture',
//   'Quality',
//   'MSP',
//   'Maize',
//   'Finance',
//   'Civil',
//   'IT',
//   'Deravative',
//   'Accounts',
//   'Production',
//   'Supply Planning',
//   'DM Plant',
//   'COGEN',
//   'HR',
//   '-',
//   'Quality Control',
//   'Environment',
//   'Maize Purchase',
//   'HSE',
//   'Crusing Operator',
//   'Export',
//   'MDH',
//   'Maize Quality Control',
//   'Process',
//   'Project',
//   'OHC',
//   'Quality Analyst',
//   'WTP',
//   'Supply Chain',
//   'SCM',
//   'Technician',
//   'Mechanical',
//   'Manufacturing Exc',
//   'DMH & LG',
//   'Manufacturing',
//   'SC',
//   'Bioproduct'
// ]

// new dept records
var dept_arr = ['Cogen', 'Store', 'Safety', 'LGMD', 'QC', 'Maintenance', 'DMH', 'ETP', 'Electrical', 'Instrumentation', 'Starch', 'Purchase', 'Industrial Purchase', 'Stores', 'Logistics', 'CWM', 'Manufacture', 'Quality', 'MSP', 'Maize', 'Finance', 'Civil', 'IT', 'Accounts', 'Production', 'Supply Planning', 'DM Plant', 'Derivative', 'COGEN', 'HR', 'Other', 'Quality Control', 'Environment', 'Maize Purchase', 'HSE', 'Crusing Operator', 'Export', 'MDH', 'Maize Quality Control', 'Process', 'Project', 'OHC', 'Quality Analyst', 'WTP', 'Supply Chain', 'SCM', 'Technician', 'Mechanical', 'Manufacturing Exec', 'DMH & LG', 'Manufacturing', 'SC', 'Bio product', 'AHMD']

var loc_to_idx = {};
for (var i = 0; i < loc_arr.length; i++) {
  loc_to_idx[loc_arr[i]] = i;
}

var dept_to_idx = {};
for (var i = 0; i < dept_arr.length; i++) {
  dept_to_idx[dept_arr[i]] = i;
}

// Social Media links
const facebook = "https://www.facebook.com/niroggyan";
const medium = "https://medium.com/@niroggyan_smart_reports";
const instagram = "https://www.instagram.com/niroggyan_reports";
const linkedin = "https://www.linkedin.com/company/NirogGyan";
const howToLink = "https://youtu.be/n0Gg2uob-X4";

export {
  howToLink,
  JSON_LINK,
  green_max,
  orange_max,
  old_min_limit,
  convert_test_index,
  facebook,
  medium,
  instagram,
  linkedin,
  loc_arr,
  dept_arr,
  loc_to_idx,
  dept_to_idx,
  total_bio_markers,
  convert_index_test,
};
