import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import { useAuth } from "../../contexts/AuthContext";
import logo from "../images/logo.jpg";
import ologo from "../images/ologo.png";
import { howToLink } from "../utils";
import "../css/style.css";
import { AiOutlineLogout } from "react-icons/ai";
import { COLORS } from "../color";
// import how_to from "../images/howto_img.png";
import { GoSettings } from "react-icons/go";
import RoquetteLogo from "../RoquetteLogo";
import OrangeStarsLogo from "../OrangeStarsLogo";

export default function Navbar(props) {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  function closeModal() {
    setOpenModal(false);
  }

  function openVideoModal() {
    setOpenModal(true);
  }


  return (

    <div className="testing">
      <nav className="navbar navbar-expand-lg navbar-light bg-light bg-new"
        ref={(el) => {
          if (el) {
            el.style.setProperty('background-color', COLORS.primary_navbar_background_color, 'important');
          }
        }}>
        <Link className="navbar-brand" to="/dashboard">
          {/* <img src={logo} width="90" height="30" /> */}

          {/* Roquette logo */}

          <RoquetteLogo />

        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto ">
            {/*<div className="dropdown">
              <li
                className="dropdown-toggle text-white text-decoration-none btn btn-warning custom-class ml-2 text-white my-1 round_test"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Navigate To
              </li>
              <ul
                className="dropdown-menu multi-level"
                role="menu"
                aria-labelledby="dropdownMenu"
              >
                <li className="dropdown-item">
                  <a href="#title">Concern Areas</a>
                </li>
                <li className="dropdown-item">
                  <a href="/profile#profile">Profile</a>
                </li>
                <li className="dropdown-divider"></li>
                <li className="dropdown-submenu">
                  <a className="dropdown-item" tabIndex="-1" href="#">
                    Insights
                  </a>
                  <ul className="dropdown-menu">
                    <li className="dropdown-item">
                      <a tabIndex="-1" href="/insights#all-employees">
                        All Company Employees
                      </a>
                    </li>
                    <li className="dropdown-item">
                      <a href="/insights#younger-employees">Younger Employees</a>
                    </li>
                    <li className="dropdown-item">
                      <a href="/insights#older-employees">Older Employees</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>*/}
            <li className="nav-item">

              {/* <img className="my-1 mr-3 second_logo" src={ologo} width="90" height="40" /> */}

              {/* OrangeStars Logo */}

              <OrangeStarsLogo />


            </li>
            <li className="nav-item fn_button" id="dashboard" >
              <Link
                to="/dashboard"
                className="text-white text-decoration-none custom-class btn  my-1 ml-2 round_test"
                ref={(el) => {
                  if (el) {
                    if (props.page === 'dashboard') {
                      el.style.setProperty('background-color', COLORS.navbar_button_background_highlight, 'important');
                      // el.style.setProperty('color', COLORS.navbar_button_text_color_highlight,'important');
                    }
                    else {
                      el.style.setProperty('background-color', COLORS.navbar_buttons_color, 'important');
                      el.style.setProperty('color', COLORS.navbar_button_text_color, 'important');
                      // el.style.setProperty('border', 'solid');
                      // el.style.setProperty('border-color', 'grey');
                    }

                  }
                }}
              >
                Dashboard
              </Link>
            </li>
            <li className="nav-item fn_button" id="insights">
              <Link
                to="/insights"
                className="text-white text-decoration-none btn custom-class ml-2 text-white my-1 round_test"
                ref={(el) => {
                  if (el) {
                    if (props.page === 'insights') {
                      el.style.setProperty('background-color', COLORS.navbar_button_background_highlight, 'important');
                      // el.style.setProperty('color', COLORS.navbar_button_text_color_highlight,'important');
                    }
                    else {
                      el.style.setProperty('background-color', COLORS.navbar_buttons_color, 'important');
                      el.style.setProperty('color', COLORS.navbar_button_text_color, 'important');
                      // el.style.setProperty('border', 'solid');
                      // el.style.setProperty('border-color', 'grey');
                    }
                  }
                }}
              >
                Insights
              </Link>
            </li>
            <li className="nav-item fn_button" id="profile">
              <Link
                to="/profile"
                className="text-white text-decoration-none btn custom-class ml-2 text-white my-1 round_test"
                ref={(el) => {
                  if (el) {
                    if (props.page === 'profile') {
                      el.style.setProperty('background-color', COLORS.navbar_button_background_highlight, 'important');
                      // el.style.setProperty('color', COLORS.navbar_button_text_color_highlight,'important');
                    }
                    else {
                      el.style.setProperty('background-color', COLORS.navbar_buttons_color, 'important');
                      el.style.setProperty('color', COLORS.navbar_button_text_color, 'important');
                      // el.style.setProperty('border', 'solid');
                      // el.style.setProperty('border-color', 'grey');
                    }
                  }
                }}
              >
                Profiles
              </Link>
            </li>
            <li className="nav-item" id="howto">
              <button
                type="button"
                className="text-white text-decoration-none btn custom-class ml-2 text-white my-1 round_test howto-spacing"
                data-toggle="modal"
                data-target="#myModal"
                ref={(el) => {
                  if (el) {
                    if (props.page === 'howto') {
                      el.style.setProperty('background-color', COLORS.navbar_button_background_highlight, 'important');
                      // el.style.setProperty('color', COLORS.navbar_button_text_color_highlight,'important');

                    }
                    else {
                      el.style.setProperty('background-color', COLORS.navbar_buttons_color, 'important');
                      el.style.setProperty('color', COLORS.navbar_button_text_color, 'important');
                    }
                  }
                }}
              >
                How to{" "}
                <span className="h5">
                  <GoSettings />
                </span>
              </button>
            </li>

            <li class="nav-item dropdown drive-css">
              <a style={{ color: "inherit" }} class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {"Reports/PDFs"}
              </a>

              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">

                <li>
                  <a
                    title="Access Smart Reports"
                    href="https://drive.google.com/drive/u/2/folders/1qtMUGoU2AiyZO8nBdNUNNwiFz0jpcvY5"
                    style={{ textDecoration: "none", color: "inherit" }}
                    alt="driveLink"
                    target="_blank"
                  >
                    <span className="drive-links-css nav-item-css" >
                      <i className="fab fa-google-drive" style={{ margin: "5px 5px 0px 0px" }}></i> Smart Reports
                    </span>
                  </a>
                </li>

                <li><hr class="dropdown-divider" /></li>

                <li>
                  <a
                    title="Access Historical Reports"
                    href="https://drive.google.com/drive/folders/1FVfv0hkwXYfFdSK0BKQzj5mAbI3g9yrD?usp=sharing"
                    style={{ textDecoration: "none", color: "inherit" }}
                    alt="driveLink"
                    target="_blank"
                  >
                    <span className="drive-links-css d-flex justify-content-center">
                      <i className="fab fa-google-drive" style={{ margin: "5px 5px 0px 0px" }}></i> Historical Reports
                    </span>
                  </a>
                </li>

                <li><hr class="dropdown-divider" /></li>

                <li>
                  <a
                    title={"Access 2D Echo & X-Ray Reports"}
                    href="https://drive.google.com/drive/folders/10vfj2x24FWwk_X1DJl-PU2vpTHfmJ97R?usp=sharing"
                    style={{ textDecoration: "none", color: "inherit" }}
                    alt="driveLink"
                    target="_blank"
                  >
                    <span className="p-2 drive-links-css d-flex justify-content-center">
                      <i className="fab fa-google-drive" style={{ margin: "5px 5px 0px 0px" }}></i> {"2D Echo & X-Ray Reports"}
                    </span>
                  </a>
                </li>

                <li><hr class="dropdown-divider" /></li>

                <li>
                  <a
                    title={"Access 2D Echo & X-Ray Reports"}
                    href="https://niroggyan.s3.ap-south-1.amazonaws.com/health-dashboard-other-files/RIPL-PPT+Analysis+Final+(1).pdf"
                    style={{ textDecoration: "none", color: "inherit" }}
                    alt="driveLink"
                    target="_blank"
                  >
                    <span className="p-2 drive-links-css d-flex justify-content-center">
                      <i className="far fa-file-pdf" style={{ margin: "5px 5px 0px 0px" }}></i> {"RIPL-PPT Analysis"}
                    </span>
                  </a>
                </li>

              </ul>
            </li>

            <li className="nav-item" id="driveLink" style={{ marginTop: "12px" }}>

            </li>
            <li className="nav-item">
              <button
                type="button"
                className="btn logout-button-css signout-css custom-class ml-2 text-white my-1 round_test"
                onClick={handleLogout}
                ref={(el) => {
                  if (el) {
                    //el.style.setProperty('background-color', COLORS.navbar_buttons_color, 'important');
                    el.style.setProperty('color', COLORS.navbar_button_text_color, 'important');
                  }
                }}
              >
                <span className="signout-css">
                  Logout{" "}
                  <i className="fas fa-sign-out-alt"></i>
                </span>
                {/* <span className="h5">
                  <AiOutlineLogout />
                </span> */}
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <div className="modal fade" id="myModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="card p-3">
              <ReactPlayer
                url={howToLink}
                width="100%"
                controls="true"
              />
              <button type="button" className="btn btn-danger mt-3" data-dismiss="modal">
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}