import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./footer";
import '../css/style.css'
import drink_water from "../images/drink_water.jpg";
import exercise from "../images/exercise.svg"
import no_smoking from "../images/no_smoking.svg"
import eat_healthy from "../images/eat_healthy.svg"
import manage_stress from "../images/manage_stress.png"
import sleep from "../images/sleep.png"
import maintain_weight from "../images/maintain_weight.png"
import sunlight from "../images/sunlight.png"
import D_suppliment from "../images/D_suppliment.jpg"
import weight_loss from "../images/weight_loss.svg"
import meat_egg from "../images/meat_egg.svg"
import sugar_free from "../images/sugar_free.svg"
import fat_free from "../images/fat_free_food.svg"
import no_drinking from "../images/no_drinking.svg"

import D_rich_food from "../images/D_rich_food.svg"
import ReactTooltip from 'react-tooltip';
import { AiFillInfoCircle } from "react-icons/ai";
import { HiOutlineLightBulb } from "react-icons/hi"
import { COLORS } from "../color";
import HealthReport from "../reportpdf/Orange_Stars-Health-Report.pdf"
import { insightsData } from "./insightsData";


class Insights extends Component {
  render() {
    return (
      <>
        <div style={{ backgroundColor: COLORS.background_color }}>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
              <Navbar page="insights" />
            </div>
          </div>
          <div className="row headings">
            {/* text-center */}

            {/* <h3 style={{paddingTop: "3vh", paddingLeft: "5vw"}}>
              Insights
            </h3> */}

            <div className="col-lg-12 text-center">
              <h1 className="bold-heading">Know what's up with your people</h1>
              <h3>Deep dive into data-driven health insights</h3>
              <br />
              {/* <a href={HealthReport} class="btn btn-success mb-4 insights-button" role="button" aria-pressed="true" alt="Click here to download detailed insights" target="_blank">Download Insights</a> */}
              {/* <a href="https://niroggyan.s3.ap-south-1.amazonaws.com/health-dashboard-insights-pdf/Corporate+Insights-PDF.pdf" style={{ textDecoration: "none"}} target="_blank">
                <span class="btn btn-success mb-4 insights-button" role="button" aria-pressed="true" alt="Click here to download detailed insights">Download Insights <i className="fas fa-download"></i></span>
              </a> */}
            </div>


          </div>
          {/* <div className="row">
          <div className="col-lg-10 col-sm-10"></div>
          </div> */}
          <div className="container-fluid insight-table-responsive-margin">

            <div className="row m-4">
              <div className="col-lg-1"></div>
              <div className="col-lg-12 col-sm-12 text-white upper_left mr-1">
                <table className="table table-bordered table-hover insight-table-curve table-white-background insights-table">
                  <thead>
                    <tr>
                      <th colspan="2" className="text-center">Company Health Insights<AiFillInfoCircle data-tip="Health analysis of your company is below" className="mb-1 insight-i-icon" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "70%" }}>
                        <HiOutlineLightBulb size={30} />
                        <span >
                          General Health condition of the employees has few areas of concern, mainly
                        </span>
                      </td>
                      <td style={{ width: "30%" }} className="text-center">

                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "70%" }}>
                        <ul>
                          <li>
                          Blood Pressure which is area of concern in both the males and females of > 35 years of Age and Only males in less than 35 years of age which require them to maintain a healthy lifestyle.

                            {/* <ul><li>
                          56 out of the 65 - 86% male employees with their Cholesterol out of range, as compared to only 40 out of 51-80% female employees with their Cholesterol out of range.
                          </li></ul> */}
                          </li>
                        </ul>
                      </td>
                      <td style={{ width: "30%" }} className="text-center">
                        <img src="https://i.ibb.co/YcZHByP/blood-pressure.png" style={{ width: "60px", height: "60px" }} alt="bp" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "70%" }}>
                        <ul>
                          <li>
                          High Cholesterol is area of concern in both the males and females of > 35 years of Age and Only males in less than 35 years of age which require them to follow a healthy lifestyle with adequate diet and nutrition, low fat intake, decrease in calorie intake
                            {/* <ul>
                                <li>
                                  43 employees had their HbA1c% higher than the recommended 5.6%, while 3 employees had their values critically high exceeding 10%.
                                </li>
                                <li>
                            The older employees in the company have a higher percentage-50% of being at risk of developing Diabetes.  
                              </li>
                              </ul> */}
                          </li>
                        </ul>
                      </td>
                      <td style={{ width: "30%" }} className="text-center">
                        <img src="https://i.ibb.co/jGSC7rd/cholesterol.png" style={{ width: "60px", height: "60px" }} alt="chol" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "70%" }}>
                        <ul>
                          <li>
                            <span className="hightlight">
                            Prediabetes with High HBA1c is a cause of concern for both the males and females of >35 years of age
                            </span>
                            {/* <ul>
                          <li>
                          104 employees have their Vitamin D values below the normal 30 ng/ml, while 5 employees have their values critically low.<br/>
                          </li>
                          <li>
                          The impact of either age or gender is statistically insignificant in the case of Vitamin D deficiency. Both males and females are equally deficient irrespective of their age.<br/>
                          </li>
                        </ul> */}
                          </li>
                        </ul>
                      </td>
                      <td style={{ width: "30%" }} className="text-center">

                        <img src="https://i.ibb.co/6H43jJS/pre-diabetes.png" style={{ width: "60px", height: "60px" }} alt="diabetes" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "70%" }}>
                        <ul>
                          <li>
                            <span>
                              Low Hemoglobin, MCH, PCV the most common cause of which is Iron Deficiency and  is main concern in the female of both greater than 35 as well as less than 35 years of age
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td style={{ width: "30%" }} className="text-center">

                        <img src="https://i.ibb.co/HTtc57D/cell-molecule.png" style={{ width: "60px", height: "60px" }} alt="cell" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "70%" }}>
                        <ul><li>
                          {"High TSH (hypothyroidism ) is main concern in women of both the age group <35 years as well as >35 years of age. "}
                        </li></ul>
                      </td>
                      <td style={{ width: "30%" }} className="text-center">
                        <img src="https://i.ibb.co/5KdGRjL/thyroid.png" style={{ width: "60px", height: "60px" }} alt="thyroid" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "70%" }}>
                        <ul><li>
                          {"Overweight/ Obesity is area of concern in women greater than 35 years of age with BMI high as well as low in both the age groups of female."}
                        </li></ul>
                      </td>
                      <td style={{ width: "30%" }} className="text-center">

                        <img
                          src={weight_loss}
                          style={{ width: "60px", height: "60px" }}
                          alt="overWeight"
                        />

                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "70%" }}>
                        <ul>
                          <li>
                            {"High Globulin is area of concern in most employees, which might be due to infection or recent vaccinations."}
                          </li></ul>
                      </td>
                      <td style={{ width: "30%" }} className="text-center">
                        <img src="https://i.ibb.co/fXqX8rh/blood-care.png" style={{ width: "60px", height: "60px" }} alt="globulin" />
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>

            {/* <div className="row m-4 " style={{justifyContent: "space-evenly"}}> */}

            {/* <div className="col-lg-5 col-sm-12 text-white upper_left mr-1 "   >
                <table className="table table-bordered table-hover bg-light height-fix">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center" id="all-employees">All Male Employees <AiFillInfoCircle data-tip="Health analysis of the male employees in your company" className="mb-1" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table-padding">
                      <div className="table-margin">
                        <HiOutlineLightBulb size={25} /> The male employees in the company have predominantly <strong>Lipids, Liver, Blood Sugar</strong> and <strong>Vitamin D</strong> values being out of range.<br/>
            <ul>
              <li>
              Out of the <span className="highlight">65 male</span> employees in the company, <span className="highlight">56 male</span> employees – <span className="highlight">86%</span> have their Lipids out of range. With 32 employees having a higher than normal LDL value.<br/>
              </li>
              <li>

              <span className="highlight">63</span> out of the <span className="highlight">65 male</span>  in the company suffer from a Vitamin D deficiency.<br/>
              </li>
          
                    <li>
                    LDL is the "bad" cholesterol, meaning fat which deposits inside blood vessels and causes heart diseases.<br/>
                    </li>
                    <li>
                    Globulins are a group of proteins in the blood. They are made in your liver by the immune system. Globulins play an important role in liver function, blood clotting, and fighting infection.<br/>
                    </li>
                    <li>
                    The HbA1c(Glycated Haemoglobin) test measures how much glucose is bound to hemoglobin. Red blood cells live for about 3 months, so the test shows the average level of glucose in your blood for the past 3 months.  
                    </li>
                  </ul>

                </div >
                    </tr>
                  </tbody>
                </table>
              </div> */}

            {/* <div className="col-lg-5 col-sm-12 upper_left" >
                <table className="table table-bordered table-hover bg-light  height-fix">
                  <thead>
                    <tr>
                      <th colspan="2" className="text-center">All Female Employees <AiFillInfoCircle data-tip="Health analysis of the female employees in your company" className="mb-1" /></th>
                    </tr>
                  </thead>
                  <tbody className="bg-light">
                    <tr>
                      <div className="table-margin">

                      <HiOutlineLightBulb size={25} /> The female employees in the company have their <strong>Kidney, Thyroid, Vitamin D</strong> and <strong>Hemoglobin</strong> levels out of range.<br/>

                    <ul>
                      <li>
                      Out of the <span className="highlight">51 female</span> employees in the company, <span className="highlight">36</span> have some Kidney parameter which is out of range. The most common being Creatinine level low, below the recommended 0.6 mg/dl. 33 female employees had their Creatinine levels lower than normal.<br/>
                      </li>
                      <li>
                      <span className="highlight">20</span> of the female employees have their Thyroid levels which are off. The parameter which is most out of range is the Thyroid Stimulating Hormone (TSH), with <span className="highlight">14 employees</span> having high TSH.<br/>

                      </li>
                    
                          <li>
                          Kidneys filter blood of your body to remove waste products & excess fluids. These waste products are produced by the breakdown of proteins (present in food, muscle & other cells) to generate energy.<br/>
                          Creatinine is a waste product that your kidneys regularly remove from your body. A high level of creatinine in your blood simply means your kidneys are not functioning properly.<br/>
                      
                          </li>
                          <li>
                          Known as the "sunshine vitamin", Vitamin D is produced by your skin when exposed to sunlight. Vitamin D is essential for strong bones - it helps your body use calcium from the diet. Thus, low vitamin D increases the chances of fracture and may also increase the chances of diabetes and heart disease.<br/>                               
                      
                          </li>
                        </ul>
                        </div>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            {/* </div> */}

            {/* <div c */}

            {/* <div className="row m-4"  style={{justifyContent: "space-evenly"}}> */}

            {/* <div className="col-lg-5 col-sm-12 upper_left text-white mr-1 " style={{padding: "0", margin: "0"}}>
                <table className="table table-bordered table-hover bg-light height-fix">
                  <thead>
                    <tr>
                      <th colspan="2" className="text-center" id="older-employees">Older Male Employees<AiFillInfoCircle data-tip="Health analysis of the older male employees (>35 years) in your company" className="mb-1" /></th>
                    </tr>
                  </thead>
                  <tbody className="bg-light">
                    <tr>
                      <div className="table-margin">
                        <HiOutlineLightBulb size={25} />The older male employees in the company have their <strong>Lipids, Liver, Blood Sugar</strong> and <strong>Vitamin D</strong> values being out of range.<br/>

            <ul>

              <li>
              <span className="highlight">16 older male employees</span> are at risk of being Diabetic. <span className="highlight">8</span> of these employees-<span className="highlight">50%</span>, have their HbA1c out of range. <br/>
              </li>
              <li>
              <span className="highlight">17</span> out of the <span className="highlight">21 older male employees</span> in the company have some or the other Liver parameter which is out of range. The Globulin level of <span className="highlight">11 employees</span> is lower than the recommended 2.5 mg/dl.<br/>

              </li>
              <li>
              All <span className="highlight">21</span> of the older male employees are Vitamin D deficient.<br/>
              </li>
                          <li>
                          Non-HDL cholesterol is basically your HDL number subtracted from your total cholesterol number. So, in other words, it's all the “bad” types of cholesterol. Ideally, you want this number to be lower rather than higher.<br/>
                          </li>
                          <li>
                          Causes of high Non- HDL levels are - Diet, Weight, Physical Activity, Smoking and Genetics.<br/>
                          </li>
                          <li>
                          Known as the "sunshine vitamin", Vitamin D is produced by your skin when exposed to sunlight. Vitamin D is essential for strong bones - it helps your body use calcium from the diet. Thus, low vitamin D increases the chances of fracture and may also increase the chances of diabetes and heart disease.<br/>
                          </li>
                        </ul>
                        
                       </div>     
                    </tr>
                  </tbody>
                </table>
              </div> */}
            {/* <div className="col-lg-5 col-sm-12 upper_left " style={{padding: "0", margin: "0"}}>
                <table className="table table-bordered table-hover bg-light  height-fix">
                  <thead>
                    <tr>
                      <th colspan="2" className="text-center">Older Female Employees <AiFillInfoCircle data-tip="Health analysis of the older female employees (>35 years) in your company" className="mb-1" /></th>
                    </tr>
                  </thead>
                  <tbody className="bg-light">
                    <tr>
                      <div className="table-margin">
                      <HiOutlineLightBulb size={25} />The older female employees have mostly their <strong>Kidney, Lipids, Thyroid, Blood Sugar, Vitamin D</strong> and <strong>Hemoglobin</strong> levels which are out of range.<br/>

                        <ul>
                          <li>
                          Out of the <span className="highlight">26</span> older female employees in the company, <span className="highlight">17</span> have some Kidney parameter which is out of range. All <span className="highlight">17</span> employees have their creatinine level low, below the recommended 0.6 mg/dl.<br/>
                          </li>
                          <li>
                          <span className="highlight">23</span> of the <span className="highlight">26 older female employees</span> in the company have a Vitamin D deficiency.<br/>
                          </li>
                          <li>
                          <span className="highlight">14</span> of the older female employees are at risk of being anemic, with all having low transferrin saturation levels.
                          </li>

                          <li>
                          Creatinine is a waste product that your kidneys regularly remove from your body. A high level of creatinine in your blood simply means your kidneys are not functioning properly.<br/>
                          </li>
                          <li>
                          Causes of low Creatnine levels are low muscle mass, liver problems, diet, pregnancy or illness.<br/>
                          </li>
                          <li>
                          The major reasons for Vitamin D deficiency are either not being exposed to adequate sunlight or not consuming adequate Vitamin D rich foods.<br/>
                          </li>
                          <li>
                          Transferrin Saturation is the percentage of transferrin occupied with iron, usually a value of less than 20% indicates a deficiency. <br/>
                          </li>
                          <li>
                          The main cause of reduced percentage is due to an iron deficiency or a prolonged infection.<br/>
                          </li>
                        </ul>
                          </div>  
                    </tr>
                  </tbody>
                </table>
              </div> */}
            {/* </div> */}
          </div>
          <div className="row headings">
            <div className="col-lg-12 text-center">
              <h1 className="bold-heading">Health Insights on all the cuts are provided here. </h1>
              <h3>Please scroll left to access all the tables.</h3>
            </div>
          </div>

          {/* New table horizontal scroll */}




          <div className="horizontal_slider">
            <div className="silder_container">


              <div className="item">
                <table style={{ width: "100%" }} className="table table-bordered bg-light height-fix shadow table-white-background">
                  <thead style={{
                    position: "-webkit-sticky", // this is for all Safari (Desktop & iOS), not for Chrome
                    position: "sticky",
                    top: "-2px",
                    zIndex: "1", // any positive value, layer order is global
                    background: "#dedede" // any bg-color to overlap
                  }}>
                    <tr style={{ fontWeight: "bold" }}>
                      <td>Test</td>
                      <td>Cause</td>
                      <td>Advice</td>
                      <td>{"Age > 35 Male / Female"}</td>
                      <td>{"Age < 35 Male / Female"}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {insightsData.tableData1.map((each, idx) => (
                      <tr key={idx}>
                        <td>{each.testname}</td>
                        <td>{each.cause}</td>
                        <td>{each.Advices.map((each, every) => (
                          <ul>
                            <li>
                              {each}
                            </li>
                          </ul>
                        ))}
                        </td>
                        <td>{each.AgeLow35MaleFemale.map((each, idx) => (
                          <ul>
                            <li>{each}</li>
                          </ul>
                        ))}</td>
                        <td>{each.AgeHigh35MaleFemale.map((each, idx) => (
                          <ul>
                            <li>{each}</li>
                          </ul>
                        ))}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>


              <div className="item">
                <table style={{ width: "100%" }} className="table table-bordered bg-light height-fix shadow table-white-background">
                  <thead style={{
                    position: "-webkit-sticky", // this is for all Safari (Desktop & iOS), not for Chrome
                    position: "sticky",
                    top: "-2px",
                    zIndex: "1", // any positive value, layer order is global
                    background: "#dedede" // any bg-color to overlap
                  }}>
                    <tr style={{ fontWeight: "bold" }}>
                      <td>Test</td>
                      <td>Cause</td>
                      <td>Advice</td>
                      <td>{"Age > 35 Male / Female"}</td>
                      <td>{"Age < 35 Male / Female"}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {insightsData.tableData2.map((each, idx) => (
                      <tr key={idx}>
                        <td>{each.testname}</td>
                        <td>{each.cause}</td>
                        <td>{each.Advices.map((each, every) => (
                          <ul>
                            <li>
                              {each}
                            </li>
                          </ul>
                        ))}
                        </td>
                        <td>{each.AgeLow35MaleFemale.map((each, idx) => (
                          <ul>
                            <li>{each}</li>
                          </ul>
                        ))}</td>
                        <td>{each.AgeHigh35MaleFemale.map((each, idx) => (
                          <ul>
                            <li>{each}</li>
                          </ul>
                        ))}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="item">
                <table style={{ width: "100%" }} className="table table-bordered bg-light height-fix shadow table-white-background">
                  <thead style={{
                    position: "-webkit-sticky", // this is for all Safari (Desktop & iOS), not for Chrome
                    position: "sticky",
                    top: "-2px",
                    zIndex: "1", // any positive value, layer order is global
                    background: "#dedede" // any bg-color to overlap
                  }}>
                    <tr style={{ fontWeight: "bold" }}>
                      <td>Test</td>
                      <td>Cause</td>
                      <td>Advice</td>
                      <td>{"Age > 35 Male / Female"}</td>
                      <td>{"Age < 35 Male / Female"}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {insightsData.tableData3.map((each, idx) => (
                      <tr key={idx}>
                        <td>{each.testname}</td>
                        <td>{each.cause}</td>
                        <td>{each.Advices.map((each, every) => (
                          <ul>
                            <li>
                              {each}
                            </li>
                          </ul>
                        ))}
                        </td>
                        <td>{each.AgeLow35MaleFemale.map((each, idx) => (
                          <ul>
                            <li>{each}</li>
                          </ul>
                        ))}</td>
                        <td>{each.AgeHigh35MaleFemale.map((each, idx) => (
                          <ul>
                            <li>{each}</li>
                          </ul>
                        ))}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>


            </div>
          </div>

        </div>
        <div className="row">
          <Footer />
        </div>
        <ReactTooltip />
      </>
    );
  };
}
export default Insights;