export const insightsData = {
    "tableData1": [
        {
            "testname": "ESR (Raised)",
            "cause": "Inflammatory condition, Infection, Joint Pain",
            "Advices": ["Treatment of the cause", "Maintaining Healthy Lifestyle", "Regular Monitoring of ESR"],
            "AgeLow35MaleFemale": ["Age related joint problem"],
            "AgeHigh35MaleFemale": ["Infection"]
        },
        {
            "testname": "Low MCH, MCV, PCV, Hb",
            "cause": "Iron deficiency anemia",
            "Advices": [
                "Cereal products, whole grain bread",
                "Nuts and seeds, e.g. walnuts, sesame, sunflower seeds, etc.",
                "Green leafy vegetables like broccoli, spinach",
                "Beans and pulses like chickpeas, black eyed beans, kidney beans, etc."
            ],
            "AgeLow35MaleFemale": ["M- Dietary intake of low Iron", "F – Dietary Intake Menstrual loss"],
            "AgeHigh35MaleFemale": ["M- Low Dietary Intake", "F- Low Intake of Green vegetables/ Iron rich food"]
        },
        {
            "testname": "Raised VLDL and cholesterol",
            "cause": "Family history, diet and less physical activity",
            "Advices": [
                "Reducing intake of saturated fats",
                "Increased physical activity such as 40 minutes of moderate to vigorous intensity exercise 3 or 4 times per week.",
            ],
            "AgeLow35MaleFemale": [
                "M- Dietary Intake, gender, Age(old age more risk)",
                "F- Dietary intake",
                "Less Physical Activity"
            ],
            "AgeHigh35MaleFemale": [
                "M- Dietary Intake",
                "F- Dietary Intake"
            ]
        },
    ],
    "tableData2": [
        {
            "testname": "High Globulin",
            "cause": "Dehydration, infection, anemia or joint pain",
            "Advices": ["Healthy diet- avoid fatty food and alcohol", "Regular follow-up treatment of underlying cause "],
            "AgeLow35MaleFemale": ["M- Infection", "F- Anemia"],
            "AgeHigh35MaleFemale": ["M- Infection", "F- Infection"]
        },
        {
            "testname": "Raised TSH",
            "cause": "Thyroid gland is underactive and not making enough thyroxine. Incidence increases with age and iodine deficiency",
            "Advices": ["T3 and T4 tests required for further treatment"],
            "AgeLow35MaleFemale": ["Incidence Increase with Age and Iodine Deficiency"],
            "AgeHigh35MaleFemale": []
        },
        {
            "testname": "MCH (Low)",
            "cause": "Cause of Low MCH in Adults is Iron Deficiency Anaemia",
            "Advices": [
                "Cereal and cereal products.",
                "Wholegrain bread",
                "Nuts and seeds - for example, hazelnuts, macadamia nuts, peanuts, pecans, walnuts, sesame seeds, sunflower seeds and pine nuts.",
                "Green leafy vegetables - for example, broccoli, spinach, watercress and kale.",
                "Beans and pulses - for example, baked beans, peas, lentils, chickpeas, black-eyed beans and kidney beans. Eggs. Meat Dried fruit - for example, raisins, apricots, prunes, currants and figs."
            ],
            "AgeLow35MaleFemale": ["M- Dietary Intake Less or Ulcer", "F- Menstrual Loss of Blood", "Less Dietary Intake"],
            "AgeHigh35MaleFemale": ["M- Dietary Intake Less or Ulcer", "F- Menstrual Loss of Blood", "Less Dietary Intake"]
        },
    ],
    "tableData3": [
        {
            "testname": "Prediabetes",
            "cause": "Family history, high BP, Overweight especially if this weight is around belly, as age increases chances of diabetes increases.",
            "Advices": [
                "Losing just 5% weight reduces risk of diabetes – exercise regularly",
                "Keep skin on vegetables and nuts. Prefer whole fruits instead of fruit juice as juices raises blood sugar quickly",
                "Eat high fibre foods such as couple of almonds and apples alongside your food"
            ],
            "AgeLow35MaleFemale": [
                "With Increasing Age , risk increase",
                "Overweight"
            ],
            "AgeHigh35MaleFemale": ["Age Overweight"]
        },
        {
            "testname": "High BMI",
            "cause": "might be due to taking in more calories through food and drink than your body needs, or not expending enough energy to use it up other factors can also cause weight gain, including eating the wrong types of food, certain medical conditions or medications, genetics, stress, lack of sleep, and age.",
            "Advices": [
                "Avoid sugary drinks and foods such as chocolates, sweets and biscuits",
                "Have plenty of fibre in diet- whole grains, oats, fruits and vegetables",
                "Moderate intensity physical activity around 60-90 minutes on at least 5 days of the week",
            ],
            "AgeLow35MaleFemale": [
                "Lack of Exercise",
                "More Fatty and Junk Food",
                "Sedentary Life Style",
                "Hypothyroid"
            ],
            "AgeHigh35MaleFemale": [
                "Sedentary Life Style",
                "High Calorie Intake"
            ]
        },
        {
            "testname": "High Blood Pressure",
            "cause": "Most of the cases are of primary hypertension. Lack of exercise, overweight",
            "Advices": [
                "Sodium reduction present in packages foods and table salt",
                "Avoid smoking",
                "Replace consumption of sucrose containing foods – sugary and sweetened beverages",
                "Non starchy vegetables, fruits, whole grains and dairy products",
                "Foods rich in omega 3 fatty acids such as fish and nuts"
            ],
            "AgeLow35MaleFemale": ["M-Sedentary Life Style, Over Weight And Lack of exercise.", "F- Lack of exercise , overweight"],
            "AgeHigh35MaleFemale": []
        },
    ]
}